import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import useSiteMetaData from '../../hooks/useSiteMetaData'

type SeoComponentProps = {
  title?: string
  description?: string
  image?: string
  internalImage?: boolean
  article?: boolean
}

const SeoComponent: React.FC<SeoComponentProps> = ({
  title,
  description,
  image,
  article,
  internalImage,
}) => {
  const { pathname } = useLocation()
  const { site } = useSiteMetaData()

  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    titleTemplate,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title ? title : defaultTitle,
    description: description || defaultDescription,
    // If image is present, check if it's an internal or external image, if internal add the site url
    image: image
      ? internalImage
        ? `${siteUrl}${image}`
        : image
      : defaultImage,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      {/* Default Meta Data */}
      <meta name='description' content={seo.description} />
      <meta name='image' content={seo.image} />

      {/* Facebook OG Tags  */}
      {seo.url && <meta property='og:url' content={seo.url} />}
      {(article ? true : null) && <meta property='og:type' content='article' />}
      {seo.title && <meta property='og:title' content={seo.title} />}
      {seo.description && (
        <meta property='og:description' content={seo.description} />
      )}
      {seo.image && <meta property='og:image' content={seo.image} />}

      {/* Twitter Tags */}
      <meta name='twitter:card' content='summary_large_image' />
      {twitterUsername && (
        <meta name='twitter:creator' content={twitterUsername} />
      )}
      {seo.title && <meta name='twitter:title' content={seo.title} />}
      {seo.description && (
        <meta name='twitter:description' content={seo.description} />
      )}
      {seo.image && <meta name='twitter:image' content={seo.image} />}

      {/* Header Colour / Notch fix for iOS */}
      <meta name='theme-color' content='#242256' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, viewport-fit=cover'
      />
    </Helmet>
  )
}
export default SeoComponent

SeoComponent.defaultProps = {
  title: undefined,
  description: undefined,
  image: undefined,
  article: false,
  internalImage: false,
}
